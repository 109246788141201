import type { Resource } from "i18next"
import currentGlobal from "./global"

declare global {
  interface Window {
    locale: string
    languageSegment?: string
    isJSDOM?: boolean
    translations?: Resource
  }
}

const processEnv = typeof process !== "undefined" && process.env ? process.env : {}

// Set defaults to use when not set otherwise
const currentEnv = currentGlobal.RAILS_ENV || processEnv.RAILS_ENV || "development"
const currentVersion = currentGlobal.SM_VERSION || processEnv.SM_VERSION || ""
const currentAdmin = currentGlobal.SM_ADMIN
const currentCustomer = currentGlobal.SM_CUSTOMER
const locale = currentGlobal.locale || "en-IE"
const languageSegment = currentGlobal.languageSegment || ""
const translations = currentGlobal.translations || null

const isJSDOM: boolean = typeof window !== "undefined" && typeof window.isJSDOM !== "undefined" && window.isJSDOM

export default {
  PRODUCTION: currentEnv === "production",
  STAGING: currentEnv === "staging",
  TEST: currentEnv === "test",
  RAILS_ENV: currentEnv,
  VERSION: currentVersion,
  admin: currentAdmin,
  customer: currentCustomer,
  locale,
  languageSegment,
  translations,
  isJSDOM,
}
